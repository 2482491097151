import axios from 'axios'

const api = 'usuarios'

const Usuarios = {
    crearUsuario(payload) {
        return axios.post(`${api}`,payload)
    },
    editarUsuario(payload, id) {
        return axios.put(`${api}/${id}`,payload)
    },
    listarUsuarios() {
        return axios.get(`${api}`)
    },
    cambiarEstadoUsuario(id) {
        return axios.put(`${api}/${id}/estado`)
    },
    eliminarUsuario(id) {
        return axios.delete(`${api}/${id}`)
    },
}

export default Usuarios