<template>
    <section class="sect-admin-tribus pl-4">
        <div class="row">
            <div class="col-12 col-lg-9">
                <div class="row mb-4">
                    <div class="col-auto my-auto">
                        <p class="f-28 font-bold-italic">ADMINISTRADORES</p>
                    </div>
                    <div class="col-auto my-auto ml-lg-auto">
                        <div class="btn-general font-medium-italic px-3 f-300 w-200px f-18 h-32px" @click="openCreateAdmin">
                            CREAR ADMINISTRADOR
                        </div>
                    </div>
                </div>
                <!--  -->
                <div class="row mx-4 mt-4">
                    <div class="col-9">
                        <div v-for="(item, index) in integrantes" :key="index" class="row mx-0 mt-4 bg-fondo inclinacion">
                            <div class="col-6 py-2   ml-2 py-2">
                                <div class="row mx-0">
                                    <div class="col-auto">
                                        <div :class="`${item.estado == 0 ? 'text-grey-light' : ''} d-middle`">
                                            <p class="f-18 fk-regular"> {{ item.nombre }} </p>
                                            <i v-if="item.estado == 0" class="icon-user-inactive ml-2" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-auto my-auto ml-auto">
                                <el-dropdown>
                                    <span class="el-dropdown-link">
                                        <div class="btn-grey wf-47px mx-2">
                                            <i class="icon-dots-vertical" />
                                        </div>
                                    </span>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item>
                                            <p @click="openChangeState(item)"> {{ item.estado == 1 ? 'Inactivar' : 'Activar' }}</p>
                                        </el-dropdown-item>
                                        <el-dropdown-item>
                                            <p @click="openEditAdmin(item)">Editar</p>
                                        </el-dropdown-item>
                                        <el-dropdown-item>
                                            <p @click="openModalDeleteAdmin(item.id)">Eliminar</p>
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modal-crear-admin @listarUsuarios="listarUsuarios" ref="refModalCrearAdmin" />
        <modal-eliminar-admin @eliminarAdmin="eliminarAdmin" ref="refModalEliminarAdmin" />
    </section>
</template>

<script>
import modalCrearAdmin from './partials/modalCrearAdmin.vue'
import modalEliminarAdmin from './partials/modalEliminarAdmin.vue'
import Usuarios from '../../services/usuarios/usuarios'
export default {
    components: {
        modalCrearAdmin,
        modalEliminarAdmin,
    },
    data() {
        return {
            rutas: [
                { titulo: 'TRIBUS', ruta: 'asd' },
                { titulo: 'PREMIOS', ruta: 'asd' },
            ],
            value2: false,
            search: '',
            integrantes: [],
            idadmin:null
        }
    },
    mounted() {
        this.listarUsuarios()
    },
    methods: {
        openModalDeleteTribe() {
            this.$refs.refModalEliminarAdmin.toggle()
        },
        openCreateAdmin() {
            this.$refs.refModalCrearAdmin.toggle()
        },
        openEditAdmin(item) {
            this.$refs.refModalCrearAdmin.toggle(item)
        },
        openModalDeleteAdmin(idadmin) {
            this.idadmin = idadmin
            this.$refs.refModalEliminarAdmin.toggle()
        },
        async listarUsuarios(){
            try {
                const { data } = await Usuarios.listarUsuarios()
                this.integrantes = data?.data ?? {}
            } catch (error) {
                const mensaje = error?.response?.data?.error ?? 'error al cambiar estado'
                this.notificacion('Error', mensaje, 'error')
            }
        },
        async openChangeState(item){

            try {
                await Usuarios.cambiarEstadoUsuario(item.id)
                this.listarUsuarios()
            } catch (error) {
                console.log("🚀 ~ file: administradores.vue:111 ~ openChangeState ~ error:", error)
                const mensaje = error?.response?.data?.error ?? 'error al cambiar estado'
                this.notificacion('Error', mensaje, 'error')
            }
        },
        async eliminarAdmin(){

            try {
                const { data } = await Usuarios.eliminarUsuario(this.idadmin);
                if (data?.exito) {
                    this.notificacion('Mensaje', "eliminado correctamente", 'success')
                    this.integrantes = this.integrantes.filter(el => el.id != this.idadmin)
                }
                this.idadmin = null
            } catch (error) {
                const mensaje = error?.response?.data?.error ?? 'error al eliminar integrante'
                this.notificacion('Error', mensaje, 'error')
            }
        
        }

    }
}
</script>

<style lang="scss" scoped>
.sect-admin-tribus{
	.text-grey-light{
		color: #637381;
	}
}
</style>