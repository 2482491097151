<template>
    <modal ref="modalCreateAdmin" :titulo="`${titulo} ADMINISTRADOR`" tamano="modal-md" no-aceptar :adicional="btnModal" @adicional="handleSave">
        <div class="row mx-0">
            <div class="col">
                <ValidationObserver ref="formCrearAdmin">
                    <div class="mb-4">
                        <p class="f-14 fk-medium">Nombre</p>
                        <ValidationProvider v-slot="{ errors }" name="nombre" rules="required|max:60">
                            <el-input v-model="model.nombre" class="w-100" placeholder="Nombre" />
                            <p class="v-error w-100 f-11"> {{ errors[0] }} </p>
                        </ValidationProvider>
                    </div>
                    <div class="mb-4">
                        <p class="f-14 fk-medium">Teléfono</p>
                        <ValidationProvider v-slot="{ errors }" name="telefono" rules="required|integer|min:10|max:10">
                            <el-input v-model="model.telefono" class="w-100" placeholder="Teléfono" />
                            <p class="v-error w-100 f-11"> {{ errors[0] }} </p>
                        </ValidationProvider>
                    </div>
                    <div class="mb-4">
                        <p class="f-14 fk-medium">Contraseña</p>
                        <ValidationProvider v-slot="{ errors }" name="contraseña" rules="min:6|max:15">
                            <el-input v-model="model.password" type="password" class="w-100" placeholder="Contraseña" />
                            <p class="v-error w-100 f-11"> {{ errors[0] }} </p>
                            <p v-if="passwordrequired && model.password == ''" class="f-11" style="color:red">El campo contraseña es obligatorio</p>
                        </ValidationProvider>
                    </div>
                </ValidationObserver>
            </div>
        </div>
    </modal>
</template>

<script>
import Usuarios from '../../../services/usuarios/usuarios'
export default {
    data() {
        return {
            titulo:'CREAR',
            btnModal:'CREAR',
            model: {
                id: '',
                nombre: '',
                password: '',
                telefono: '',
            },
            passwordrequired:false
        }
    },
    methods: {
        toggle(item) {
            this.reset()
            if (item) {
                this.titulo = 'EDITAR'
                this.btnModal = 'GUARDAR'
                this.model =  {
                    id: item.id,
                    nombre: item.nombre,
                    password: '',
                    telefono: item.telefono
                }
            }else{
                this.passwordrequired = true
            }
            this.$refs.modalCreateAdmin.toggle();
        },
        async handleSave() {
            try {
                const valid = await this.$refs.formCrearAdmin.validate()
                if(!valid) return
                if (this.passwordrequired && this.model.password == '') return
                if(this.model.id == '') await Usuarios.crearUsuario(this.model);
                else await Usuarios.editarUsuario(this.model, this.model.id);
                this.$emit('listarUsuarios')
                this.$refs.modalCreateAdmin.toggle();

            } catch (error) {
                const mensaje = error?.response?.data?.error ?? 'error al crear usuario'
                this.notificacion('Error', mensaje, 'error')
            }
        },
        reset() {
            this.model = {
                id: '',
                nombre: '',
                password: '',
                telefono: ''
            }
            this.titulo = 'CREAR'
            this.btnModal = 'CREAR'
            this.passwordrequired = false
        }
    }
}
</script>